import React from 'react';
import styled from 'styled-components';
import NewsListBoxBase from '@components/common/news-list-box';
import { mixin, size } from '@src/settings';

export const NewsListBox = styled(NewsListBoxBase)`
	margin-bottom: 0;
	margin-top: ${ size.pageFirstGap*0.1 }rem;
	margin-bottom: ${ size.pageLastGap*0.1 }rem;
	padding-top: 5rem;
	padding-bottom: 6rem;
`;

export const TitleCont = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 3.5rem;
`;
export const Title = styled.h3`
	flex: auto 1 1;
	font-size: 1.9rem;
	line-height: 1.3;
`;

export const PostDate = styled.p`
	flex: auto 0 0;
	color: #F00;
	font-size: 1.6rem;
	font-weight: 700;
	line-height: 1;
`
const baseGap = 20;
export const Post = styled.section`
	font-size: 1.5rem;
	font-weight: 300;
	line-height: 2.1;
	p{
		margin-bottom: 1rem;
	}
	h2{
		font-size: 1.9rem;
		line-height: 1.3;
		margin-bottom: 1.5rem;
		* + &{
			margin-top: 5rem;
		}
	}
	h3{
		font-size: 1.7rem;
		line-height: 1.3;
		margin-bottom: 1.5rem;
		* + &{
			margin-top: 3rem;
		}
	}
	h4{
		font-size: 1.5rem;
		line-height: 1.3;
		margin-bottom: 1.4rem;
		* + &{
			margin-top: 3rem;
		}
	}
	figure{
		& > figcaption{
			margin-top: 1rem;
			font-size: 1.3rem;
			line-height: 1.3;
		}
		& + *{
			margin-top: ${ baseGap*0.1 }rem;
		}
	}
	img{
		display: block;
		width: 100%;
	}
	p + .wp-block-image{
		margin-top: 3rem;
	}
	.wp-block-gallery{
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		&.columns-3{
			& > .blocks-gallery-item{
				flex-basis: calc((100% - 1rem*2)/3);
				&:not(:nth-child(3n + 3)){
					margin-right: 1rem;
				}
				&:not(:nth-child(-n + 3)){
					margin-top: 1rem;
				}
			}
		}
	}
`
